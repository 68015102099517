<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-dark" id="start">
      <title-and-description title="Digital Marketing">
        <p>
          We understand the online behaviours of the different target audience groups across the different markets we operate and with 
          this key consumer insight, we build social media strategies for our clients. These focus on using the most effective and relevant 
          channels, along with an injection of creativity, to create value and solid business results.
        </p>
      </title-and-description>
      <services-list :serviceList="digitalServices" />
      <hr />
      <title-and-description title="Social Media">
        <p>
          Today’s consumer lives online and social media plays a significant role. In this market specifically, the influence of Instagram, 
          Facebook, YouTube, TikTok, Twitter and Snapchat is growing on a daily basis. Social media, if implemented strategically and creatively, 
          can grow brand awareness, create personality, change perceptions and drive desire to purchase. Everything we do is designed to spark 
          conversation and drive results.
        </p>
      </title-and-description>
      <services-list :serviceList="socialMedia" />
    </container>
    <container type="bg-light-image background-cover">
      <v-row>
        <v-col><h1 class="text-center">Some of Our Projects</h1></v-col>
      </v-row>
      <project-grid :filter="['Digital']" type="light" />
      <center>
        <v-btn tile large color="primary" class="my-10" to="work"
          >View all of our work</v-btn
        >
      </center>
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import ProjectGrid from "../components/ProjectGrid.vue";
import ServicesList from "../components/blocks/ServicesList.vue";

export default {
  name: "Digital",
  components: {
    vHeader,
    ProjectGrid,
    ServicesList,
  },
  data() {
    return {
      image: require("@/assets/backgrounds/digital.jpg"),
      overlayCopy: [
        {
          copy: "What Separates Good",
          size: "40",
        },
        {
          copy: "content from great",
          size: "40",
        },
        {
          copy: "content is to know your",
          size: "40",
        },
        {
          copy: "audience",
          size: "40",
        },
      ],
      digitalServices: ["Google Ads", "Youtube Advertising", "PPC Campaigns", "Metasearch", "Google Analytics"],
      socialMedia: [
        "Social Media Guidelines",
        "Social Media Advertising Campaigns",
        "Content Calendar Creation",
        "Scheduling Across Multiple Platforms",
        "Tone of Voice Document",
        "Daily Consumer Engagement",
        "Live Posting from Events",
        "Rich Content Creation",
        "Influencer Outreach and Engagement",
        "Measurement and Evaluation",
      ],
    };
  },
};
</script>
